<template>
  <div class="important-page-c display--flex fd--column">
    <!-- 搜索 -->
    <div
      class="search-form btn-2b5a95"
    >
<div class="l-w" :style="{
        height: $store.state.global.isSearchExtend_1
          ? ''
          : $store.state.global.searchFormHeight_1 + 10 + 'px',
      }">
      <el-form
        label-position="right"
        :class="$store.state.global.isSearchExtend_1 ? '' : 'overflow--hidden'"
				:model="searchForm"
				@submit.native.prevent
      >
        <el-form-item label="类型:">
          <el-select 
						v-model="searchForm.type" 
						@keyup.enter.native="seach"
						@visible-change="visibleType"
						ref="selectref"
						clearable>
            <el-option label="投诉" value="1" />
            <el-option label="建议" value="2" />
          </el-select>
        </el-form-item>
        <el-form-item label="投诉人:">
          <el-input 
						v-model.trim="searchForm.complainants" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="录入人:">
          <el-input 
						v-model.trim="searchForm.insertusername" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
        <el-form-item label="教学点:">
          <el-input 
						v-model.trim="searchForm.schoolorgname" 
						@keyup.enter.native="seach"
						clearable />
        </el-form-item>
      </el-form>
    </div>
      <div class="r-w">

      <el-button
        type="primary"
        icon="el-icon-search"
        @click="seach"
        :loading="!$store.state.global.isEndRequest"
        class="btn-2b5a95"
        >搜索</el-button
      >
			<el-button type="primary" @click="resetQuery">重置</el-button>
      <el-button
        type="primary"
        :icon="
          $store.state.global.isSearchExtend_1
            ? 'el-icon-arrow-up'
            : 'el-icon-arrow-down'
        "
        v-if="$store.state.global.searchExtend_1"
        class="btn-2b5a95"
        @click="onExtendClick('isSearchExtend_1')"
        >{{ $store.state.global.searchExtendText_1 }}</el-button
      >
    </div>

    </div>

    <!-- 按钮操作 -->
    <div
      class="Btn-w eaf4ff-btn"
      v-if="tableRes.buttonlist && tableRes.buttonlist.length"
      :style="{
        height: $store.state.global.isBtnExtend_1
          ? ''
          : $store.state.global.BtnHeight_1 + 10 + 'px',
      }"
    >
      <div
        class="btn-l"
        v-if="tableRes.buttonlist"
        :class="$store.state.global.isBtnExtend_1 ? '' : 'overflow--hidden'"
      >
        <el-button
          v-for="(item, index) in tableRes.buttonlist"
          :key="index"
          type="primary"
          @click="onClickBtn(item.buttonname)"
          >{{ item.name }}
        </el-button>
      </div>
      <div class="Btn-extend">
        <el-button
          type="primary"
          v-if="$store.state.global.btnExtend_1"
          class="btn-2b5a95"
          :icon="
            $store.state.global.isBtnExtend_1
              ? 'el-icon-arrow-up'
              : 'el-icon-arrow-down'
          "
          @click="onExtendClick('isBtnExtend_1')"
          >{{ $store.state.global.BtnExtendText_1 }}</el-button
        >
      </div>
    </div>

    <!-- 表格 -->
    <el-table
      :data="tableRes.list"
      stripe
      border
      :header-cell-style="{ background: '#044d8c', color: '#fff'}"
      ref="multipleTable"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column label="类型" prop="type" align="center">
        <template slot-scope="scope">
          <el-button @click="onDetail(scope.row)" type="text">
            {{ complaint[scope.row.type] }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="投诉人" prop="complainants" align="center" />
      <el-table-column
        label="投诉人联系方式"
        prop="complainantsphone"
        align="center"
      />
      <el-table-column label="投诉时间" align="center" width="160">
        <template slot-scope="scope">
          {{ scope.row.complainttime | formatDate("YYYY-MM-DD HH:mm:ss") }}
        </template>
      </el-table-column>
      <el-table-column label="投诉对象" prop="respondentname" align="center" />
      <el-table-column
        label="录入人姓名"
        prop="insertusername"
        align="center"
      />
      <el-table-column label="处理标识" prop="flag" align="center">
        <template slot-scope="scope">
          {{ scope.row.flag === 1 ? "已处理" : "未处理" }}
        </template>
      </el-table-column>
      <el-table-column label="教学点" prop="schoolorgname" align="center" />
      <el-table-column label="分公司" prop="companyorgname" align="center" />
    </el-table>

    <!-- 分页 -->
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :current-page="pageinfo.pageindex"
      :page-size="pageinfo.pagesize"
      :page-sizes="[10, 20, 30, 40, 50]"
      layout="total, prev, pager, next, jumper"
      :total="tableRes.totalcount"
    ></el-pagination>

    <!--添加，修改-->
    <el-dialog
      class="btn-2b5a95"
      width="1100px"
			v-dialogDrag
			:close-on-click-modal='false'
      :title="dialogTitle"
      :visible.sync="showAddModal"
      v-if="showAddModal"
      append-to-body
      @close="onCloseAdd"
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="100px"
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item prop="companyorgname" label="所属分公司">
          <el-select
            v-model="dynamicValidateForm.companyorgname"
            placeholder="请选择"
            :disabled="!$store.state.login.isMaxAuth"
            clearable
            @change="onChangeCompany($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.companyList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="schoolorgname" label="所属教学点">
          <el-select
            v-model="dynamicValidateForm.schoolorgname"
            placeholder="请选择"
            clearable
            @change="onChangeSchool($event, 'dynamicValidateForm')"
          >
            <el-option
              v-for="item in $store.state.selectData.schoolList"
              :key="item.key"
              :label="item.val"
              :value="item.key"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="类型:">
          <el-select
            v-model="dynamicValidateForm.typename"
            clearable
            @change="(val, prop) => onChangeSelect(val, 'type')"
          >
            <el-option
              v-for="item in complaints"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <div v-if="dynamicValidateForm.type == 1">
          <el-form-item label="投诉人:">
            <el-input v-model.trim="dynamicValidateForm.complainants" clearable/>
          </el-form-item>
          <el-form-item label="投诉人联系方式:">
            <el-input
              v-model.trim="dynamicValidateForm.complainantsphone"
              maxlength="11"
							clearable
            />
          </el-form-item>
          <el-form-item label="投诉对象">
            <el-input v-model="dynamicValidateForm.respondentname" disabled />
            <el-button
              class="select-btn"
              type="primary"
              @click="onSelectData(user_dialoguserresult5,'showComplaintModal')"
              >选择</el-button
            >
          </el-form-item>
          <el-form-item label="投诉时间:">
            <el-date-picker
              placeholder="选择日期"
              v-model="dynamicValidateForm.complainttime"
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd HH:mm:ss"
            />
          </el-form-item>
        </div>
        <el-form-item label="内容:">
          <div id="complaint_editor" ref="editor" style="width:90%"></div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitAddOrEditForm('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>

    <!-- 投诉人列表 -->
    <el-dialog
      class="btn-2b5a95"
      width="50%"
			v-dialogDrag
			:close-on-click-modal='false'
      @close="onCloseSelect"
      title="选择投诉人："
      :visible.sync="showComplaintModal"
			v-if="showComplaintModal"
      append-to-body
    >
      <el-button type="primary" @click="onConfirmComplaint">确定</el-button>

      <div class="index_2 btn-2b5a95">
        <div
          class="l-w"
          :style="{
            height: $store.state.global.isSearchExtend_2
              ? ''
              : $store.state.global.searchFormHeight_2 + 10 + 'px',
          }"
        >
          <el-form
            label-position="right"
            :class="
              $store.state.global.isSearchExtend_2 ? '' : 'overflow--hidden'
            "
            :model="selectSearchForm"
						@submit.native.prevent
          >
            <el-form-item label="姓名:" prop="realname">
              <el-input 
								v-model="selectSearchForm.realname" 
								@keyup.enter.native="onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )"
								clearable />
            </el-form-item>
          </el-form>
        </div>
        <div class="r-w">
          <el-button
            type="primary"
            icon="el-icon-search"
            :loading="!$store.state.global.isEndRequest"
            class="btn-2b5a95"
            @click="
              onSelectSearchClick(
                selectTableUrl,
                null,
                Object.assign({}, selectSearchForm)
              )
            "
            >搜索</el-button
          >
					<el-button @click="handleRestChange">重置</el-button>
          <el-button
            type="primary"
            :icon="
              $store.state.global.isSearchExtend_2
                ? 'el-icon-arrow-up'
                : 'el-icon-arrow-down'
            "
            v-if="$store.state.global.searchExtend_2"
            class="btn-2b5a95"
            @click="onExtendClick('isSearchExtend_2')"
            >{{ $store.state.global.searchExtendText_2 }}</el-button
          >
        </div>
      </div>
      <el-table
        :data="selectTable.list"
        style="width: 100%"
        ref="moneyTable"
				:key="key"	
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column label="流水号" prop="id" align="center" />
        <el-table-column label="姓名" prop="realname" align="center" />
        <el-table-column label="性别" prop="sexval" align="center" />
        <el-table-column label="手机" prop="contactphone" align="center" >
					<template slot-scope="scope">
							  {{ scope.row.contactphone | hideMobile(scope.row.followuserid)}}
					</template>
				</el-table-column>
        <el-table-column label="教学点" prop="schoolorgname" align="center" />
      </el-table>
      <el-pagination
        background
        @current-change="(val, data)=>handleSelectChange(val,selectSearchForm)"
        :current-page="selectPageInfo.pageindex"
        :page-size="selectPageInfo.pagesize"
        :page-sizes="[10, 20, 30, 40, 50]"
        layout="total, prev, pager, next, jumper"
        :total="selectTable.totalcount"
      ></el-pagination>
    </el-dialog>

    <!-- 查看 -->
    <el-dialog
      class="btn-2b5a95"
      title="查看职员异动"
			v-dialogDrag
			:close-on-click-modal='false'
      :visible.sync="showDetailModal"
      v-if="showDetailModal"
      width="35%"
      append-to-body
    >
      <el-form :model="dynamicValidateForm" class="form-item-w-50">
        <el-form-item label="流水号:">{{
          dynamicValidateForm.id
        }}</el-form-item>
        <el-form-item label="类型:">{{
          dynamicValidateForm.type === 1 ? "投诉" : "建议"
        }}</el-form-item>
        <el-form-item label="处理标识:">
          {{
            dynamicValidateForm.flag === 1 ? "已处理" : "未处理"
          }}</el-form-item
        >
        <el-form-item label="投诉人:">{{
          dynamicValidateForm.complainants
        }}</el-form-item>

        <el-form-item label="	投诉人联系方式:">{{
          dynamicValidateForm.complainantsphone
        }}</el-form-item>
        <el-form-item label="处理结果:">{{
          dynamicValidateForm.result
        }}</el-form-item>
        <el-form-item label="投诉时间:">{{
          dynamicValidateForm.complainttime | formatDate("YYYY-MM-DD HH:mm:ss")
        }}</el-form-item>
        <el-form-item label="投诉对象:">{{
          dynamicValidateForm.respondentname
        }}</el-form-item>
        <el-form-item label="录入人姓名:">{{
          dynamicValidateForm.insertusername
        }}</el-form-item>
        <el-form-item label="教学点:">{{
          dynamicValidateForm.schoolorgname
        }}</el-form-item>
        <el-form-item label="分公司:">{{
          dynamicValidateForm.companyorgname
        }}</el-form-item>
        <el-form-item label="内容:">
          <div v-html="dynamicValidateForm.content"></div>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!--处理结果-->
    <el-dialog
      class="btn-2b5a95"
      width="600px"
			v-dialogDrag
      title="处理结果："
      :visible.sync="showResult"
			v-if="showResult"
			:close-on-click-modal='false'
      append-to-body
    >
      <el-form
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
        label-width="20%"
        class="demo-dynamic form-item-w-100"
        status-icon
        :rules="rules"
      >
        <el-form-item label="处理结果：" prop="result">
          <el-input v-model.trim="dynamicValidateForm.result" type="textarea" clearable/>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          @click="submitResult('dynamicValidateForm')"
          :loading="!$store.state.global.isEndRequest"
          >提交
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import E from "wangeditor";
import { _complaint, _complaints } from "@/assets/js/filedValueFlag";
import { part } from "@/utils/mixins";
import { mapActions } from "vuex";
import {
  complaint_list,
  complaint_input,
  complaint_save,
  complaint_delete,
  complaint_show,
  user_dialoguserresult5,
  user_getuser,
  complaint_handle,
} from "@/request/api/allChildrenProject";
import { myRequest } from "@/request/index";
import {
  selectCheck,
  confirmCallBack,
  messageTip,
  createObjParams,
  formatDare,
} from "@/utils/util";
import { _pictureupload_save, _fileupload_save } from "@/request/api/public";

export default {
  mixins: [part],
  name: "complaint",
  components: {},
  inject: ["reload"],
  props: {
    user_dialoguserresult5: {
      default: () => user_dialoguserresult5,
    },
    complaints: {
      default: () => _complaints,
    },
    complaint: {
      default: () => _complaint,
    },
  },
  data() {
    //数值表单规则
    var checkfield = (rule, value, callback) => {
      if (!(value + "")) {
        return callback(new Error("必填字段"));
      } else {
        if (isNaN(Number(value))) {
          callback(new Error("请输入数字值"));
        } else {
          callback();
        }
      }
    };
    return {
      transferDialog: false, //导出框

      rowItem: null, //表格项
      searchForm: {},
      tableRes: {}, //列表接口
      pageinfo: {
        pageindex: 1,
        pagesize: 10,
      }, //页数查询
      showAddModal: false, //显示表单框
      multipleSelection: [], //选中的表格项
      dynamicValidateForm: {}, //表单
      rules: {
        name: [{ trigger: "blur", message: "必填字段", required: true }],
      }, //表单规则
      dialogTitle: "", //对话框标题

      showDetailModal: false,

      selectTable: {},
      selectTableUrl: "",
      selectPageInfo: {
        pageindex: 1,
        pagesize: 10,
      },
      selectSearchForm: {},
      showComplaintModal: false,
      showResult: false, //处理结果
			key: '',
      editor: null, //富文本
      fds: new FormData(),
    };
  },
  created() {
    this.getDataList();
    this.org_combox();
    this.org_comboxchild(this.dynamicValidateForm.companyorgid);
		window.addEventListener("keyup", this.enterSelect);
  },
  methods: {
    ...mapActions("global", ["onExtendClick"]),
    ...mapActions("selectData", ["org_combox", "org_comboxchild"]),
    //改变分公司 change
    onChangeCompany(val, form) {
      this[form].schoolorgname = "";
      this[form].companyorgid = val;
      this.org_comboxchild(val);
    },
    onChangeSchool(val, form) {
      this[form].schoolorgid = val;
    },
    //选择器改变 change
    onChangeSelect(val, prop) {
      this.dynamicValidateForm[prop] = val;
    },
    //关闭导出框
    onCloseExport(bool) {
      this.transferDialog = false;
    },
    //导出 click
    btnExport() {
      this.transferDialog = true;
    },
		enterSelect(e) {
			if(e.keyCode == 13 ) {
				this.getDataList();
			}
		},
		visibleType(e) {
			if(!e) {
				this.$refs.selectref.blur();
			}
		},
		reset() {
			this.searchForm = {
				type: null,
				complainants: null,
				insertusername: null,
				schoolorgname: null
			}
		},
		resetQuery() {
			this.reset();
			this.getDataList();
		},
		searchSelect() {
			this.selectSearchForm = {
				realname: null
			}
			this.onSelectData(this.selectTableUrl, null, null);
		},
		//重置
		handleRestChange() {
			this.searchSelect();
		},
    //搜索 click
    seach() {
      this.pageinfo.pageindex = 1;
      this.getDataList();
    },
    //列表查询 api
    getDataList() {
      myRequest({
        url: complaint_list,
        data: Object.assign(this.searchForm, this.pageinfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.tableRes = res.data.data;
        }
      });
    },
    //切换当前页
    handleCurrentChange(val) {
      this.pageinfo.pageindex = val;
      this.getDataList();
    },
    //切换选项表格页
    handleSelectChange(val,data) {
      this.selectPageInfo.pageindex = val;
      this.onSelectData(this.selectTableUrl,null,data);
    },
    //触发所有btn方法
    onClickBtn(methodsName) {
      this.$options.methods[methodsName].call(this); //btnAdd ... （防止修改this指向）
    },
    //详情 click
    onDetail(row) {
      this.dynamicValidateForm = JSON.parse(JSON.stringify(row));
      this.showDetailModal = true;
    },
    //添加click
    btnAdd() {
      this.dialogTitle = "添加";
      this.dynamicValidateForm = {
        rank: 0,
        schoolorgid: this.$store.state.login.userinfo.schoolorgid,
        companyorgid: this.$store.state.login.userinfo.companyorgid,
      };
      this.$set(
        this.dynamicValidateForm,
        "companyorgname",
        this.$store.state.login.userinfo.companyorgname
      );
      this.$set(
        this.dynamicValidateForm,
        "schoolorgname",
        this.$store.state.login.userinfo.schoolorgname
      );
      this.org_comboxchild(this.dynamicValidateForm.companyorgid);
      if (this.editor) {
        this.editor.txt.html("");
      }
      this.showAddModal = true;
      this.initEditor();
    },
    //修改click
    btnEdit() {
      this.dialogTitle = "修改";
      selectCheck(this.multipleSelection, "修改", false, () => {
        this.editRoutePage(this.multipleSelection[0].id);
      });
    },
    //删除click
    btnDel() {
      selectCheck(this.multipleSelection, "删除", true, () => {
        confirmCallBack({
          title: "提示",
          content: "此操作将永久删除选中的数据！",
          success: () => {
            this.delTableItem(this.multipleSelection.map((i) => i.id));
          },
          fail: () => {
            messageTip({
              message: "取消操作",
              type: "info",
            });
          },
        });
      });
    },
    //刷新 click
    btnReload() {
      this.getDataList();
    },
    //处理结果 click
    btnResult() {
      selectCheck(this.multipleSelection, "操作", false, () => {
        this.dynamicValidateForm = {
          id: this.multipleSelection[0].id,
        };
        this.showResult = true;
      });
    },
    //提交处理结果 api click
    submitResult() {
      myRequest(
        {
          url: complaint_handle,
          data: this.dynamicValidateForm,
        },
        {
          that: this,
          methodName: "reload",
        }
      );
    },
    //确认投诉人 click
    onConfirmComplaint() {
      selectCheck(this.multipleSelection, "确认投诉人", false, () => {
        this.getComplaint(this.multipleSelection[0].id);
      });
    },
    //修改路由页 api
    editRoutePage(id) {
      myRequest({
        url: complaint_input,
        data: {
          id: id,
        },
      }).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm = res.data.data.vcomplaint;
          this.org_comboxchild(this.dynamicValidateForm.companyorgid);
          this.dynamicValidateForm.complainttime = formatDare(
            this.dynamicValidateForm.complainttime,
            "YYYY-MM-DD HH:mm:ss"
          );
          this.$set(
            this.dynamicValidateForm,
            "typename",
            this.complaint[this.dynamicValidateForm.type]
          );
          this.showAddModal = true;
          this.initEditor();
          delete this.dynamicValidateForm.inserttime; //inserttime 该字段不用传
          delete this.dynamicValidateForm.isoktime; //isoktime 该字段不用传
        }
      });
    },
    //提交-（添加，修改） api
    submitAddOrEditForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          myRequest(
            {
              url: complaint_save,
              data: this.dynamicValidateForm,
            },
            {
              isRefTableList: true,
              that: this,
              modal: "showAddModal",
            }
          );
        }
      });
    },
    //删除 api
    delTableItem(idArr) {
      myRequest(
        {
          method: "post",
          url: complaint_delete,
          data: this.$qs.stringify(
            {
              ids: idArr,
            },
            {
              arrayFormat: "repeat",
            }
          ),
        },
        {
          isRefTableList: true,
          that: this,
        }
      );
    },

    //选项表格 api（投诉人）
    onSelectData(url, modal, data) {
      this.selectTableUrl = url;
      myRequest({
        url: this.selectTableUrl,
        data: Object.assign(data || {}, this.selectPageInfo),
      }).then((res) => {
        if (res.data.code === "200") {
          this.$refs.multipleTable && this.$refs.multipleTable.clearSelection();
          this.selectTable = res.data.data;
					this.key = Math.random();
          this[modal] = true;
        }
      });
    },
    //选项表格 - 搜索 click
    onSelectSearchClick(url,modal,data){
      this.selectPageInfo.pageindex = 1
      this.onSelectData(url,modal,data)
    },
    //关闭选项框 click
    onCloseSelect() {
      this.selectPageInfo.pageindex = 1;
    },

    //获取选择的投诉人 api
    getComplaint(id) {
      myRequest(
        {
          url: user_getuser,
          data: {
            id: id,
          },
        },
        {
          disableTip: true,
        }
      ).then((res) => {
        if (res.data.code === "200") {
          this.dynamicValidateForm.respondentid = res.data.data.vuser.id;
          this.dynamicValidateForm.respondentname =
            res.data.data.vuser.realname;
          this.showComplaintModal = false;
					this.searchSelect();
					this.multipleSelection = [];
        }
      });
    },
    //选中表格数据改变时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //初始化富文本编辑器 event
    initEditor() {
      const that = this;
      this.$nextTick(() => {
        if (this.editor) {
          return this.editor.txt.html(this.dynamicValidateForm.content);
        }
        this.editor = new E("#complaint_editor");
        this.editor.config.withCredentials = true;
        this.editor.config.uploadFileName = "upfile";
        this.editor.config.customUploadImg = function (
          resultFiles,
          insertImgFn
        ) {
          that.fds.append("upfile", resultFiles[0]); // 传文件
          myRequest({
            method: "post",
            url: _fileupload_save,
            data: that.fds,
          }).then((res) => {
            if (res.data.code === "200") {
              that.fds = new FormData();
              // 上传图片，返回结果，将图片插入到编辑器中
              insertImgFn(that.$url.upload + res.data.url);
            }
          });
        };
        this.editor.config.onchange = (html) => {
          this.dynamicValidateForm.content = html;
        };
        this.editor.create();
        this.editor.txt.html(this.dynamicValidateForm.content);
			
      });
    },
    //关闭添加，修改框 click
    onCloseAdd(){
      this.editor = null
    },
  },
  beforeDestroy() {
		this.editor.destroy();
		this.editor = null;
	}
};
</script>

<style  lang="scss">
</style>